import { IApiResult } from 'plugins/data-models';
export class AutoCompleteController {
  searchText: string;
  constructor(private readonly searchCallback: (searchText) => Promise<IApiResult>, private readonly format?, private readonly preloadCallback?: () => Promise<IApiResult>) {
  }

  async preload() {
    if (this.preloadCallback) {
      const apiResult: IApiResult = await this.preloadCallback();

      if (apiResult?.ok && apiResult.results.length > 1) {
        for (const result of apiResult.results) {
          this.createSuggestion(result);
        }
        return apiResult;
      }
    }
  }

  async search(searchText) {
    this.searchText = searchText;
    const apiResult: IApiResult = await this.searchCallback(searchText);

    if (apiResult?.ok) {
      for (const result of apiResult.results) {
        this.createSuggestion(result);
      }
      return apiResult;
    }
  }

  formatSuggestion(suggestion) {
    if (this.format) {
      return this.format(suggestion);
    }
    else {
      return suggestion.toString();
    }
  }

  createSuggestion(suggestion) {
    suggestion.selectedText = this.formatSuggestion(suggestion);
    return suggestion;
  }
}
