import { Aurelia, autoinject, Container, FrameworkConfiguration } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';
import { NavigationInstruction, Next, PipelineStep, RedirectToRoute, Router, RouterConfiguration } from 'aurelia-router';
import { localStorageMiddleware, MiddlewarePlacement, rehydrateFromLocalStorage, Store } from 'aurelia-store';
import { get } from 'idb-keyval';
import { AuthService } from 'services/auth-service';
import { HubService } from 'services/hub-service';
import { IState } from 'services/state/state';

@autoinject()
export class App {

  public isSignedIn: boolean = false;
  public router: Router;

  constructor(private readonly aurelia: Aurelia, private readonly store: Store<IState>, private readonly i18n: I18N) {
    this.store.registerMiddleware(localStorageMiddleware, MiddlewarePlacement.After, { key: 'app-storage' });
    this.store.registerAction('Rehydrate', rehydrateFromLocalStorage);
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.title = '';
    config.addAuthorizeStep(AuthorizeStep);
    config.map([
      { route: '', name: 'welcome', moduleId: PLATFORM.moduleName('./pages/welcome'), title: 'Welcome' },
      { route: 'privacy-policy', name: 'privacy-policy', moduleId: PLATFORM.moduleName('./pages/privacy-policy'), title: 'Privacy Policy' },
      { route: '/account/login', name: 'login', moduleId: PLATFORM.moduleName('./pages/account/login'), title: 'Login' },
      { route: '/account/logout', name: 'logout', moduleId: PLATFORM.moduleName('./pages/account/logout'), title: 'Logout' },
      { route: '/account/register', name: 'register', moduleId: PLATFORM.moduleName('./pages/account/register'), title: 'Register' },
      { route: '/account/register-email-send', name: 'registerEmailSend', moduleId: PLATFORM.moduleName('./pages/account/register-email-send'), title: 'Register Email Send' },
      { route: '/account/email-confirmed', name: 'emailConfirmed', moduleId: PLATFORM.moduleName('./pages/account/email-confirmed'), title: 'Email Confirmed' },
      { route: '/account/forgot-password', name: 'forgotPassword', moduleId: PLATFORM.moduleName('./pages/account/forgot-password'), title: 'Forgot Password' },
      { route: '/account/forgot-password-confirmation', name: 'forgotPasswordConfirmation', moduleId: PLATFORM.moduleName('./pages/account/forgot-password-confirmation'), title: 'Forgot Password Confirmation' },
      { route: '/account/reset-password', name: 'resetPassword', moduleId: PLATFORM.moduleName('./pages/account/reset-password'), title: 'Reset Password' },
      { route: '/account/reset-password-confirmation', name: 'resetPasswordConfirmation', moduleId: PLATFORM.moduleName('./pages/account/reset-password-confirmation'), title: 'Reset Password Confirmation' },
      { route: '/account/change-password', name: 'changePassword', moduleId: PLATFORM.moduleName('./pages/account/manage/change-password'), title: 'Change Password', settings: { t: 'account.changePassword' } },
      { route: '/account/manage', name: 'manage', moduleId: PLATFORM.moduleName('./pages/account/manage'), title: 'Manage', settings: { auth: true } },

      // { route: '/modules', name: 'modules', moduleId: PLATFORM.moduleName('./pages/modules/index'), title: 'Modules', settings: { auth: true } },
      { route: '/pharmacies', name: 'choosePharmacy', moduleId: PLATFORM.moduleName('./pages/pharmacies/index'), title: 'Pharmacies', settings: { auth: true } },
      { route: '/settings', name: 'settings', moduleId: PLATFORM.moduleName('./pages/settings/index'), title: 'Settings', nav: false, settings: { auth: false } },

      { route: '/admin', name: 'admin', moduleId: PLATFORM.moduleName('./plugins/admin/app'), title: 'Admin', nav: true, settings: { auth: true } },
      { route: '/blister', name: 'blister', moduleId: PLATFORM.moduleName('./plugins/blister/app'), title: 'Blister', nav: true, settings: { auth: true } },
      { route: '/carehome', name: 'carehome', moduleId: PLATFORM.moduleName('./plugins/carehome/app'), title: 'Carehome', nav: true, settings: { auth: true } },
    ]);

    this.router = router;
  }

  public async attached() {
    $(document).ready(function () {
      $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open');
      });
    });

    const locale = await get<{ title: string, code: string }>('selectedLocale');
    if (locale) {
      await this.i18n.setLocale(locale.code);
    }

  }

  public async bind() {
    await this.store.dispatch(rehydrateFromLocalStorage, 'app-storage');
  }

  public stateChanged(newState) {
    this.isSignedIn = newState.isValid;
  }

  public loadPlugins() {

    const framework: FrameworkConfiguration = new FrameworkConfiguration(this.aurelia);

    framework.feature(PLATFORM.moduleName('plugins/admin/index'));
    framework.feature(PLATFORM.moduleName('plugins/blister/index'));
    framework.feature(PLATFORM.moduleName('plugins/carehome/index'));

    return framework.apply();
  }
}

class AuthorizeStep implements PipelineStep {
  public run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
    Container.instance.get(HubService).ensure();
    if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) {
      const authService = Container.instance.get(AuthService);
      const isLoggedIn = authService.isAuthenticated();
      if (isLoggedIn === undefined || !isLoggedIn) {
        return next.cancel(new RedirectToRoute('login', { username: authService.currentUser()?.userName }));
      }
    }

    return next();
  }
}
