import { BindingEngine, bindingMode, Disposable, observable } from 'aurelia-binding';
import { inject, Optional } from 'aurelia-dependency-injection';
import { DOM } from 'aurelia-pal';
import { TaskQueue } from 'aurelia-task-queue';
import { bindable, InlineViewStrategy } from 'aurelia-templating';
import { Focus } from 'aurelia-templating-resources';
import { autoCompleteOptions } from './autocompleteoptions';
import { AutoCompleteController } from '.';

let nextID = 0;

@inject(Element, BindingEngine, TaskQueue, Optional.of(Focus))
export class Autocomplete {
  @bindable controller: AutoCompleteController;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
  @bindable title = '';
  @bindable placeholder = '';
  @bindable disabled = false;
  @bindable delay = 300;
  @bindable small = false;
  @bindable horizontal = false;
  @observable inputValue = '';
  @bindable autoSelectOnSingleMatch = false;

  @bindable minChars = 0;

  focusSubscription: Disposable;
  suggestionView: InlineViewStrategy;

  id = nextID++;
  expanded = false;
  noresults = false;
  updatingInput = false;
  suggestions = [];
  index = -1;
  suggestionsUL = null;
  userInput = '';

  isWaiting = false;

  cache = {};

  constructor(private readonly element: Element, private readonly bindingEngine: BindingEngine, private readonly taskQueue: TaskQueue, private readonly focus: Focus) {
    this.element = element;
    this.bindingEngine = bindingEngine;
    this.taskQueue = taskQueue;

    this.focusSubscription = null;
    if (focus) {
      this.focusSubscription = this.bindingEngine.propertyObserver(focus, 'value')
        .subscribe(this.focusChanged.bind(this));
    }

    this.suggestionView = new InlineViewStrategy(autoCompleteOptions.suggestionTemplate);
  }

  detached() {
    if (this.focusSubscription !== null) {
      this.focusSubscription.dispose();
    }
  }

  display(name) {
    this.updatingInput = true;
    this.inputValue = name;
    this.updatingInput = false;
  }

  formatSuggestion(suggestion) {
    if (suggestion === null) {
      return '';
    }
    return this.controller.formatSuggestion(suggestion);
  }

  collapse() {
    this.expanded = false;
    // this.index = -1;
  }

  expand() {
    this.noresults = false;
    this.expanded = true;
  }

  select(suggestion) {
    this.value = suggestion;
    const name = this.formatSuggestion(this.value);
    this.userInput = name;
    this.display(name);
    this.collapse();
  }


  valueChanged() {
    if (this.value) {
      this.select(this.value);
    }
    else {
      // console.log('valueChanged');
      return this.preload();
    }
  }

  addToCache(key: string, value: any[]) {

    this.cleanEmptyCacheEntries();

    for (let i = 1; i < key.length - this.minChars; i++) {
      const part = key.slice(0, key.length - i);
      if (part in this.cache) {
        delete this.cache[part];
        continue;
      }
    }

    this.cache[key] = value;
    this.suggestions = this.cache[key];
    this.select(this.suggestions[0]);
    this.inputValue = key;
    this.expand();
  }

  private cleanEmptyCacheEntries() {
    // tslint:disable-next-line: no-for-in
    for (const part in this.cache) {
      if (!this.cache[part].length) {
        delete this.cache[part];
      }
    }
  }

  async preload() {
    if (this.cache) {
      const value = '#preloaded#';
      let suggestions;
      if (value in this.cache) {
        suggestions = this.cache[value];
      } else {
        this.isWaiting = true;
        const apiResults = await this.controller.preload();
        if (apiResults?.results) {
          suggestions = apiResults.results;
          this.cache[value] = suggestions;
        } else {
          // console.log('cancelled or other error', apiResults);
          suggestions = [];
        }
      }

      this.index = -1;
      this.suggestions.splice(0, this.suggestions.length, ...suggestions);
      if (suggestions.length === 1) {
        this.select(suggestions[0]);
      } else if (suggestions.length === 0) {
        this.collapse();
      } else {
        this.expand();
      }

      this.isWaiting = false;
    }
  }

  async inputValueChanged(value) {
    if (this.updatingInput) {
      return;
    }
    this.userInput = value;
    value = value.trim();
    if (value === '' || value.length < this.minChars) {
      this.value = null;
      // this.collapse();
      return this.preload();
    }

    let suggestions;
    if (value in this.cache) {
      suggestions = this.cache[value];
    } else {
      // no http fetch requests if previous suggestions were empty
      for (let i = 1; i < value.length - this.minChars; i++) {
        const part = value.slice(0, value.length - i);
        if (part in this.cache && !this.cache[part].length) {
          suggestions = [];
          break;
        }
      }

      if (!suggestions) {
        this.isWaiting = true;
        const apiResults = await this.controller.search(value);
        if (apiResults?.results) {
          suggestions = apiResults.results;
          this.cache[value] = suggestions;
        } else {
          // console.log('cancelled or other error', apiResults);
          suggestions = [];
        }
      }
    }

    this.index = -1;
    this.suggestions.splice(0, this.suggestions.length, ...suggestions);
    if (suggestions.length === 1 && this.autoSelectOnSingleMatch) {
      this.select(suggestions[0]);
    } else if (suggestions.length === 0) {
      this.collapse();
      this.noresults = true;
    } else {
      this.expand();
    }

    this.isWaiting = false;
  }

  scroll(key) {
    const ul = this.suggestionsUL;
    const li = ul.children.item(this.index === -1 ? 0 : this.index);
    if (key === 40 && li.offsetTop + li.offsetHeight > ul.offsetHeight) { // down key
      ul.scrollTop += li.offsetHeight;
    } else if (li.offsetTop - li.offsetHeight < ul.scrollTop) {
      ul.scrollTop = li.offsetTop - li.offsetHeight;
    }
  }

  keydown(key) {
    if ((key === 40 || key === 38) && !this.expanded) {
      this.expanded = this.suggestions.length > 0;
      return true;

    }

    if (!this.expanded) {
      return true;
    }

    // down
    if (key === 40) {
      if (this.index < this.suggestions.length - 1) {
        this.index++;
        this.display(this.formatSuggestion(this.suggestions[this.index]));
        this.scroll(key);
      }
      return;
    }

    // up
    if (key === 38) {
      if (this.index > 0) {
        this.index--;
        this.display(this.formatSuggestion(this.suggestions[this.index]));
        this.scroll(key);
      } else {
        this.index = -1;
        this.display(this.userInput);
      }
      return;
    }

    // escape
    if (key === 27) {
      this.display(this.userInput);
      this.collapse();
      return;
    }

    // enter || tab
    if (key === 13 || key === 9) {
      if (this.index >= 0) {
        this.select(this.suggestions[this.index]);
      }
      return;
    }

    return true;
  }

  onFocus() {
    if (this.value === null && !this.noresults) {
      return this.preload();
    }
  }

  blur() {
    if (!this.noresults) {
      this.select(this.value);
    }
    this.taskQueue.queueMicroTask(() => this.element.dispatchEvent(DOM.createCustomEvent('blur')));

  }

  suggestionClicked(suggestion) {
    this.select(suggestion);
  }

  focusChanged(newFocus, oldFocus) {
    if (newFocus) {
      this.taskQueue.queueMicroTask(() => {
        this.element.querySelector('input').focus();
      });
    }
  }

  focusMe() {
    this.taskQueue.queueMicroTask(() => {
      this.element.querySelector('input').focus();
    });
  }
}
