import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';
import { AutoCompleteConfiguration } from './autocompleteconfiguration';
import { AutoCompleteController } from './autocompletecontroller';

export { AutoCompleteController };

export function configure(aurelia: FrameworkConfiguration, callback) {

  const config = new AutoCompleteConfiguration();

  if (typeof callback === 'function') {
    callback(config);
  }

  aurelia.globalResources(PLATFORM.moduleName('./autocomplete'));
}
