import {
  bindable,
  noView,
  customElement,
  processContent,
} from "aurelia-framework";
import { inject } from "aurelia-dependency-injection";


@processContent(false)
@customElement("markdown")
@noView
@inject(Element)
export class Markdown {
  root = null;
  @bindable model = null;

  constructor(private readonly element: Element) {
    this.element = element;
  }

  attached() {
    this.root = this.element.shadowRoot || this.element;
    if (!this.model) {
      this.valueChanged(this.element.innerHTML);
    } else {
      this.valueChanged(this.model);
    }
  }

  modelChanged() {
    this.valueChanged(this.model);
  }

  valueChanged(newValue: string) {
    if (!this.root) return;
    this.root.innerHTML = newValue;
  }
}

